import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Pagination, Table, Spinner, Alert } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { DataManager } from '../../../core/data-manager';
import { ViewManager } from '../../../core/view-manager';
import FieldPreview from '../../../components/Entity/FieldPreview';
import ModalDetails from '../../../components/Modals/ModalDetails';
import { Utils } from '../../../core/utils';
import _ from "lodash";
import SearchPro from '../../../components/Entity/SearchPro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ModalList({ entity = null, searchObj = null, status, closeModal, modalSorting = null }) {

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const limit = 20
    const [data, setData] = useState(null)
    const fields = Utils.cloneObject(ViewManager.getVisibleColumns(entity));
    const properties = Utils.cloneObject(ViewManager.getEntityProperties(entity));
    const searchableFields = Utils.cloneObject(ViewManager.getSearchableColumns(entity));
    const [defaultSearchObj, setSearchObj] = useState(searchObj ? searchObj : {
        'query': '',
        'fields': searchableFields,
        'constraints': []
    });


    useEffect(() => {
        if (entity !== null && status === true) {
            getData(page, limit)
        }
    }, [page, limit, entity, defaultSearchObj, status]);


    async function getData(page, limit) {
        var sorting = { "field": 'id', "order": "desc" }
        if(modalSorting) {
            sorting = modalSorting
        }
        if (defaultSearchObj === null) {
            var response = await DataManager.getItems(entity, page, limit, sorting)
        }
        else {
            let query = defaultSearchObj.query || ''
            let fields = defaultSearchObj.fields || []
            let constraints = defaultSearchObj.constraints || []
            var response = await DataManager.searchItems(entity, page, limit, sorting, query, fields, constraints)
        }
        if (response.success === 1) {
            setData(response.body);
            setTotalPages(response.pages)
        }
        else {
            console.warn(response)
        }
    }

    /* paginations */
    function goToPrevPage() {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    function goToNextPage() {
        if (page < totalPages) {
            setPage(page + 1)
        }
    }
    function goToFirstPage() {
        if (page !== 1) {
            setPage(1)
        }
    }
    function goToLastPage() {
        if (page !== totalPages) {
            setPage(totalPages)
        }
    }


    function close() {
        setData(null)
        setPage(1)
        closeModal()
    }


    /* modals details */
    const [modalDetailsData, setModalDetailsData] = useState(null)
    function open(i) {
        let item = data[i]
        setModalDetailsData(item)
    }
    function closeModalDetails() {
        setModalDetailsData(null)
    }


    function search(obj) {
        if (_.isEqual(obj, searchObj)) {
            // oggetti uguali non triggero la ricerca
        }
        else {
            setPage(1)
            setSearchObj(obj)
        }
    }



    if (status === true) {
        return (
            <>
                <Modal tabIndex={1} show={status} onHide={close} size="xl" scrollable={true} backdrop="static" backdropClassName='backdropped'>
                    <Modal.Header closeButton style={{ backgroundColor: '#f9f9f9' }}>
                        <Modal.Title>{LanguageProvider.get('entity.' + entity)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{minHeight: 200}}>
                        {(entity !== null && data !== null && fields !== null && properties !== null) ? (
                            <>
                                <SearchPro entity={entity} setSearchObj={search} searchObj={searchObj} />

                                <Table bordered striped hover responsive className='small mt-3'>
                                    <thead className='small'>
                                        <tr>
                                            {fields.map((label, j) => {
                                                var l = LanguageProvider.getLabel(entity, label);
                                                return (
                                                    <th key={j} style={{ backgroundColor: '#bbb' }}>
                                                        {l}
                                                    </th>
                                                )
                                            })}
                                            <th style={{ width: 140, backgroundColor: '#bbb' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody className='small'>
                                        {data.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    {fields.map((label, j) => {
                                                        return (
                                                            <td key={j}>
                                                                <FieldPreview item={item} field={label} props={properties} entity={entity} />
                                                            </td>
                                                        )
                                                    })}
                                                    <td className='text-center'>
                                                        <Button size="sm" variant='primary' onClick={() => open(i)}>Dettagli <FontAwesomeIcon icon="chevron-right" className='ms-1' /></Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>

                                {data.length === 0 ? (
                                    <Alert variant='warning' className='small'>Nessun risultato trovato.</Alert>
                                ) : null}

                            </>
                        ) : (
                            <Spinner />
                        )}


                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', backgroundColor: '#f9f9f9' }}>
                        <Row>
                            <Col xs={12} md={6} className='my-1'>
                                <Pagination className='m-0'>
                                    <Pagination.First className={page === 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                                    <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                                    <Pagination.Item key={page}>
                                        {page}
                                    </Pagination.Item>
                                    <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                                    <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                                </Pagination>
                            </Col>
                            <Col xs={12} md={6} className='text-end my-1'>
                                <Button variant="secondary" onClick={close} className='mx-1'>
                                    {LanguageProvider.get('defaults.close')}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>


                <ModalDetails entity={entity} props={properties} data={modalDetailsData} closeModalDetails={closeModalDetails} />
            </>
        );
    }
};