//import logo from './logo.svg';
import logo from './logo.png';

export const settings = {
    "title": "Serini CRM",
    "apiUrl": "https://api.crm.mobiliserini.it/api",
    "ckdPath": "https://api.crm.mobiliserini.it/",
    "tinyKey": "uxd8thxx2a96p0tne20wyfcdx7zvpyprm38j5q5w9qqauy2p",
    "googleMapKey": "AIzaSyDwVSRwXezXuTLG8-VAe1KfNKo7zLPlYJQ",
    "config": {
        "limit": 50,
        "sortingField": 'id',
        "sortingOrder": 'desc'
    },
    "configEntity": {
        "customer_offer": {
            "limit": 20,
            "sortingField": 'id',
            "sortingOrder": 'desc'
        },
        "gyv_customer_offer": {
            "limit": 20,
            "sortingField": 'id',
            "sortingOrder": 'desc'
        },
        "customer_order": {
            "limit": 20,
            "sortingField": 'id',
            "sortingOrder": 'desc'
        },
        "gyv_customer_order": {
            "limit": 20,
            "sortingField": 'id',
            "sortingOrder": 'desc'
        },
        "supplier_order": {
            "limit": 20,
            "sortingField": 'id',
            "sortingOrder": 'desc'
        },
        "gyv_supplier_order": {
            "limit": 20,
            "sortingField": 'id',
            "sortingOrder": 'desc'
        },
        "customer": {
            "limit": 50,
            "sortingField": 'name',
            "sortingOrder": 'asc'
        },
        "supplier": {
            "limit": 50,
            "sortingField": 'name',
            "sortingOrder": 'asc'
        },
        "sales_agent": {
            "limit": 50,
            "sortingField": 'name',
            "sortingOrder": 'asc'
        }
    },
    "theme": {
        "siderbarIconColor": 'rgba(233,236,239,.5)'
    },
    "logo": logo,
    "data_print": {
        "name": 'di Serini Simone e Marta Snc',
        "address": 'Via Cesare Battisti 30, 60030 Mergo (AN), IT',
        "phone": 'Tel: 0731 814806',
        "fax": 'Fax: 0731 814806',
        "email": 'info.mergo@mobiliserini.it',
        "iva": '',
        "cf": '',
        "website": 'www.mobiliserini.it'
    },
    "stampe" : {
       "arredatore": true, 
       "sconto": true, 
       "listino": true, 
       "condVendita": true, 
       "firmaVenditore": true
    },
    "dati_pagamento": {
        "iban": "",
        "banca": "",
        "intestatario": "",
    },
    "iva_label": 'inclusa',
    "iva_esclusa": false,
}